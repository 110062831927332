import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const scrollElem = document.querySelector('.js-scroll-trigger')

let mm = gsap.matchMedia();


if (typeof gsap !== 'undefined' && scrollElem) {
    let st = null
    gsap.set('.js-enjoy-title', {
        alpha: 1
    })
    gsap.set('.js-enjoy-field', {
        alpha: 1
    })
    gsap.set('.js-swiper-balance', {
        alpha: 0
    })
    gsap.set('.js-glas', {
        alpha: 0,
    })
    gsap.set('.js-glas-b', {
        alpha: 0,
    })
    gsap.set('.js-heart', {
        className: "field balance__field balance__field--heart js-heart"
    })
    gsap.set('.js-color', {
        className: "field balance__field balance__field--color js-color"
    })
    gsap.set('.js-aroma', {
        className: "field balance__field balance__field--aroma js-aroma"
    })
    gsap.set('.js-taste', {
        className: "field balance__field balance__field--taste js-taste"
    })
    gsap.set('.js-balance-title', {
        alpha: 0,
    })


    mm.add("(min-width: 768px)", () => {
        let tl = gsap.timeline({
            defaults: {
                ease: "power2",
            },
        })
            .add('step1')
            .to('.js-enjoy-title', {
                yPercent: -10,
                alpha: 0,
            }, 'step1')

            .add('step2')
            .to('.js-enjoy-field', {
                alpha: 0,
                yPercent: -20,
            }, 'step2')
            .to('.js-balance-title', {
                alpha: 1,
                yPercent: 10,
            }, 'step2')

            .add('step3')
            .to('.js-glas', {
                alpha: 1
            }, 'step3')

            .add('step4')
            .to('.js-balance-title', {
                alpha: 1,
            }, 'step4')

            .add('step5')
            .to('.js-glas-b', {
                alpha: 1
            }, 'step5')
            .to('.js-heart', {
                className: "field balance__field balance__field--heart desktop js-heart animation-heart"
            }, 'step5')
            .to('.js-color', {
                className: "field balance__field balance__field--color desktop js-color animation-color"
            }, 'step5')
            .to('.js-aroma', {
                className: "field balance__field balance__field--aroma desktop js-aroma animation-aroma"
            }, 'step5')
            .to('.js-taste', {
                className: "field balance__field balance__field--taste desktop js-taste animation-taste"
            }, 'step5')

        st = ScrollTrigger.create({
            duration: 10,
            trigger: scrollElem,
            pin: true,
            scrub: 1,
            stop: true,
            start: "center center",
            end: "bottom center",
            animation: tl,
        })
    })

    mm.add("(max-width: 767px)", () => {
        let tlTwo = gsap.timeline({
            defaults: {
                ease: "power2",
            },
        })
            .add('step1')
            .to('.js-enjoy-title', {
                yPercent: -10,
                alpha: 0,
            }, 'step1')

            .add('step2')
            .to('.js-enjoy-field', {
                alpha: 0,
                yPercent: -20,
            }, 'step2')
            .to('.js-balance-title', {
                alpha: 1,
                yPercent: 10,
            }, 'step2')

            .add('step3')
            .to('.js-glas', {
                alpha: 1
            }, 'step3')

            .add('step4')
            .to('.js-balance-title', {
                alpha: 1,
            }, 'step4')

            .add('step5')
            .to('.js-glas-b', {
                alpha: 1
            }, 'step5')
            .to('.js-swiper-balance', {
                alpha: 1
            }, 'step5')

        st = ScrollTrigger.create({
            duration: 10,
            trigger: scrollElem,
            pin: true,
            scrub: 1,
            stop: true,
            start: "center center",
            end: "bottom center",
            animation: tlTwo,
        })
    })
}
