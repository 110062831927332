const cookiesElem = document.querySelector('.js-cookies')
function getCookie(name) {
    const cookie_arr = document.cookie.split('; ');
    const cookie_obj = {};

    for (let i=0; i<cookie_arr.length; i++) {
        const nv = cookie_arr[i].split('=');
        cookie_obj[nv[0]] = nv[1];
    }

    return cookie_obj[name];
}

if (cookiesElem) {
    const btnCookiesElems = cookiesElem.querySelectorAll('.js-cookies-btn')
    btnCookiesElems.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            cookiesElem.classList.remove('_active')
            document.cookie = "balt_cookies=yes; path=/; max-age=31536000"
        })
    })
    window.addEventListener('load', () => {
        if (getCookie('balt_cookies') !== 'yes') {
            cookiesElem.classList.add('_active')
        }
    })
}
