import 'intersection-observer'

const industryBlock = document.querySelectorAll('.js-industry')
if (industryBlock) {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const elems = entry.target.querySelectorAll('.industry__elem')
                if (elems) {
                    elems.forEach(elem => {
                        elem.classList.add('animation-industry')
                    })
                }
                observer.unobserve(entry.target)
            }
        })

    }, {threshold: 0.5})

    industryBlock.forEach((item) => observer.observe(item))
}

