import Popup from '../components/popup'

export const openPopup = function (popupId, callback, onCloseCallback) {
  const popupTemplate = document.getElementById(popupId)

  if (popupTemplate) {
    return new Popup(popupTemplate.innerHTML, popupId, callback, onCloseCallback)
  }
}

export const openPopupByBtn = function (btnElem, callback, onCloseCallback) {
  btnElem.addEventListener('click', function (evt) {
    if (!btnElem.disabled && btnElem.dataset.popup) {
      evt.preventDefault()

      openPopup(btnElem.dataset.popup, callback, onCloseCallback)
    }
  })
}
