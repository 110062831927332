import {FORM_ERROR_CLASS} from  "../assets/common";

export default function (formElem, fieldParentElem = null) {
  let formErrorElems = Array.from(formElem.querySelectorAll(`.${FORM_ERROR_CLASS}`))

  if (formErrorElems.length) {
    formErrorElems.forEach((formErrorElem) => {
      formErrorElem.remove()
    })
  }
}
