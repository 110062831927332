
const btnYes = document.querySelector('.js-yes')
const btnNo = document.querySelector('.js-no')
const attentionBanner = document.querySelector('.js-attention')
const attentionBannerNo = document.querySelector('.js-attention-no')

window.addEventListener('DOMContentLoaded', () => {
    if (!sessionStorage.getItem('_yes-agree') && !sessionStorage.getItem('_no-agree')) {
        document.body.classList.add('attention-body')
        attentionBanner.classList.add('_active')
    } else if (sessionStorage.getItem('_no-agree')) {
        document.body.classList.add('attention-body')
        attentionBannerNo.classList.add('_active')
    }
})

if (btnYes && btnNo) {
    btnYes.addEventListener('click', () => {
        sessionStorage.setItem('_yes-agree', 'true')
        document.body.classList.remove('attention-body')
        attentionBanner.classList.remove('_active')
    })

    btnNo.addEventListener('click', () => {
        sessionStorage.setItem('_no-agree', 'true')
        attentionBanner.classList.remove('_active')
        attentionBannerNo.classList.add('_active')
    })
}
