import createErrorElem from './_createErrorElem'
import clearFormErrors from './_clearFormErrors'

import {
  FORM_ERROR_PLACE_BEFORE
} from  "../assets/common";


export default function (options) {
  const {formElem, message, callback} = options

  if (formElem) {
    clearFormErrors(formElem)

    if (message) {
      const newFormErrorElem = createErrorElem(message)
      const formErrorPositionBeforeElem = formElem.querySelector(`.${FORM_ERROR_PLACE_BEFORE}`)

      if (formErrorPositionBeforeElem) {
        formErrorPositionBeforeElem.parentNode.insertBefore(newFormErrorElem, formErrorPositionBeforeElem)

      } else {
        formElem.append(newFormErrorElem)
      }
    }

    if (typeof callback !== 'undefined') {
      callback()
    }
  }
}
