
const swiperSlider = document.querySelector('.js-slider')
if (swiperSlider) {

    const breakpointLg = window.matchMedia('(max-width: 1000px)')

    let eatSlider

    const checkBreakpoint = () => {
        if (eatSlider !== undefined) {
            eatSlider.destroy(true, true)
        }
        const swiper = new Swiper(".js-slider2", {
            slidesPerView: 1,
            freeMode: true,
            effect: "fade",
        })

        if (breakpointLg.matches) {
            eatSlider = new Swiper(swiperSlider, {
                slidesPerView: "auto",
                navigation: {
                    nextEl: '.combination__next',
                    prevEl: '.combination__prev',
                },
                thumbs: {
                    swiper: swiper,
                },
            })

        } else {
            let rotation = swiperSlider.style.transform.match(/rotate\((\d+)(.+)\)/)
            let [num] = rotation.slice(1)
            const rotateSwiper = function () {
                if (this.activeIndex > this.previousIndex) {
                    num -= 120
                    swiperSlider.style.transform = 'rotate(' + num + 'deg)'
                    const activeImg = swiperSlider.querySelector('.js-img._active')
                    activeImg.nextElementSibling.classList.add('_active')
                    activeImg.classList.remove('_active')
                } else {
                    num += 120
                    swiperSlider.style.transform = 'rotate(' + num + 'deg)'
                    const activeImg = swiperSlider.querySelector('.js-img._active')
                    activeImg.previousElementSibling.classList.add('_active')
                    activeImg.classList.remove('_active')

                }
            }
            eatSlider = new Swiper(swiperSlider, {
                slidesPerView: "auto",
                effect: "creative",
                navigation: {
                    nextEl: '.combination__next',
                    prevEl: '.combination__prev',
                },
                thumbs: {
                    swiper: swiper,
                },
                on: {
                    activeIndexChange: rotateSwiper,
                }
            })
        }
    }
    breakpointLg.addListener(checkBreakpoint)
    checkBreakpoint()
}

    const placeSlider = document.querySelector('.js-place-slider')
    const placeSlider2 = document.querySelector('.js-place-slider2')
if (placeSlider) {
    const placeSwiper = new Swiper(placeSlider, {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 16,
        loopedSlides: 5,
        freeMode: {},
        loop: true,
        speed: 50000,
        autoplay: {
            delay: 100,
            disableOnInteraction: false
        },

        on: {

            slideChangeTransitionStart() {
            },

            slideChangeTransitionEnd() {
            }
        }
    })

    const placeSwiper2 = new Swiper(placeSlider2, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loopedSlides: 5,
        freeMode: {},
        loop: true,
        speed: 50000,
        autoplay: {
            delay: 100,
            disableOnInteraction: false
        },

        on: {

            slideChangeTransitionStart() {
            },

            slideChangeTransitionEnd() {
            }
        }
    })


}
const swiperBalance = document.querySelector('.js-swiper-balance')
if (swiperBalance) {
    const swiperAsk = new Swiper('.js-swiper-balance', {
        slidesPerView: 'auto',
        spaceBetween: 10,
        loopedSlides: 5,
        freeMode: {},
        loop: true,
        speed: 70000,
        autoplay: {
            delay: 0,
            disableOnInteraction: true
        },

        on: {

            slideChangeTransitionStart() {
            },

            slideChangeTransitionEnd() {
            }
        }
    })
}
