import {openPopupByBtn} from './assets/_openPopup'
import 'intersection-observer'
import './components/menu'
import './components/scrollTrigger'
import './components/slider'
import './components/load'
import './components/industry'
import './components/rating'
import './components/cookies'
import './components/popup-partner'
import {Form} from "./components/form";

(function () {
  Array.from(document.getElementsByClassName('js-open-popup'))
    .forEach((btnElem) => {
      openPopupByBtn(btnElem)
    })

    const formBlock = document.querySelector('.js-form')

    const form = new Form(formBlock)
})()




