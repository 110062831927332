import showFormError from './_showFormError'
import {
    ACTIVE_CLASS
} from  "../assets/common";
export class Form {
    constructor(form) {
        this._formElement = form
        this._inputs = this._formElement.querySelectorAll('.js-input')
        this._inputsCheck = []
        this._steps = this._formElement.querySelectorAll('.js-step')
        this._tabs = this._formElement.querySelectorAll('.js-tab')
        this._blockTab = this._formElement.querySelector('.js-tabs')
        this._formResult = this._formElement.querySelector('.js-result')
        this.tabActive = ''
        this.submit = this._formElement.querySelector('.js-submit')
        this._inputHandler()
    }

    _sendForm() {
        fetch(this._formElement.action, {
            method: this._formElement.method,
            body: new FormData(this._formElement)

        }).then((response) => {
            return response.json()

        }).then((data) => {
            if (data.isSuccess) {
                this._formElement.reset()


            } else {
                showFormError({
                    formElem: this._formElement,
                    message: data.message,
                    callback: () => {
                    }
                })
            }
        })
    }

    _noActiveStepHandler() {
        this._steps.forEach((step) => {
            step.classList.remove(ACTIVE_CLASS)
        })
    }

    _noActiveTabHandler() {
        this._tabs.forEach((tab) => {
            tab.classList.remove(ACTIVE_CLASS)
        })
    }

    _activeTabAndStep() {
        this._steps.forEach((step) => {
            if (step.classList.contains(ACTIVE_CLASS)) {
                const dataOpen = step.dataset.open
                this.tabActive = this._formElement.querySelector('[data-tab=' + dataOpen + ']')
                this.tabActive.classList.add(ACTIVE_CLASS)
            }
        })
    }

    _inputHandler() {
        this._inputs.forEach((input) => {
                input.addEventListener('input', (e) => {
                    e.preventDefault()
                    this._inputsCheck.push(input)
                    this._noActiveStepHandler()
                    this._noActiveTabHandler()
                    input.closest('.js-step').nextElementSibling.classList.add(ACTIVE_CLASS)
                    if (this._inputsCheck.length < 3) {
                        this._activeTabAndStep()
                    }
                    if (this._inputsCheck.length === 3) {
                        this._formEventSubmit()
                    }
                })
        })
    }

    _formEventSubmit() {
        this._formElement.addEventListener('submit', (evt) => {
            evt.preventDefault()
            this._formResult.classList.add(ACTIVE_CLASS)
            this._blockTab.classList.remove(ACTIVE_CLASS)
            this._sendForm()
        })
        this.submit.click()
    }
}
