export default function toggleAgreement(btnElem) {
  btnElem.addEventListener('click', function (evt) {
    evt.preventDefault()

    const agreementId = btnElem.dataset.agreement
    const agreementElem = document.getElementById(agreementId)

    if (agreementElem) {
      agreementElem.classList.toggle('agreement_open')
    }
  })
}
