import {ACTIVE_CLASS} from "../assets/common"

export class Rating {
    constructor(starBlock) {
        this.starBlock = starBlock
        this.stars = this.starBlock.querySelectorAll('.js-star')
        this.starText = document.querySelector('.star__text')
        this.starsAr = []
        this.starsArColor = []
        this.onPushArray()
        this._starHoverHandler()
        this._starOutHandler()
    }

    onPushArray() {
        this.stars.forEach((star) => {
            this.starsAr.push(star)
        })
    }

    _starHoverHandler() {
        this.stars.forEach((star) => {
            star.addEventListener('mouseover', (e) => {
                e.preventDefault()
                const n = parseInt(star.dataset.starNum)
                this.newAr = this.starsAr.slice(0, n)
                this.newAr.forEach((item) => {
                    item.classList.add(ACTIVE_CLASS)
                })
                const text = star.querySelector('.js-input-text').textContent
                this.starText.textContent = text
            })
        })
    }
    _starOutHandler() {
        this.stars.forEach((star) => {
            star.addEventListener('mouseout', (e) => {
                star.classList.remove(ACTIVE_CLASS)
                this.starText.textContent = ''
            })
        })
    }

}

const starBlock = document.querySelector('.js-stars')
const starRating = new Rating(starBlock)
