import {openPopup} from "../assets/_openPopup";

(function () {
  const MENU_OPEN_CLASS = 'nav--opened'
  const BTN_OPEN_CLASS = 'burger-btn--opened'
  const BODY_MENU_OPEN_CLASS = 'body-opened-menu'

  const menuElement = document.querySelector('.js-menu')
  const menuBtn = document.querySelector('.js-open-top-menu')
  const elementHeader = document.querySelector('.js-header')
  const bodyElement = document.body

  if (menuBtn && menuElement) {
    menuBtn.addEventListener('click', function (evt) {
      evt.preventDefault()
      elementHeader.classList.toggle('active')
      menuBtn.classList.toggle(BTN_OPEN_CLASS)
      menuElement.classList.toggle(MENU_OPEN_CLASS)
      bodyElement.classList.toggle(BODY_MENU_OPEN_CLASS)
    })
  }
    const links = document.querySelectorAll('.nav__link')
    links.forEach((link) => {
      link.addEventListener('click', () => {
        menuBtn.classList.toggle(BTN_OPEN_CLASS)
        menuElement.classList.toggle(MENU_OPEN_CLASS)
        bodyElement.classList.remove(BODY_MENU_OPEN_CLASS)
        if (link.hash && !document.querySelector('#popup-advertising-min-element') && !sessionStorage.getItem("keyLink")) {
          openPopup('popup-advertising-min')
        }
      })
    })
})()

