import {openPopup, openPopupByBtn} from '../assets/_openPopup'
import toggleAgreement from '../assets/_toggleAgreement'

(function () {
    const LOADING_CLASS = 'form-loading'
    const ERROR_MESSAGE = 'Произошла ошибка. <br>Попробуйте еще раз'
    const FORM_ERROR_CLASS = 'js-form-error'

    function showFormSuccess(formElem, message) {
        formElem.classList.remove(LOADING_CLASS)
        formElem.innerHTML = `<p class="popup__text-success">${message}</p>`
    }

    function showFormError(formElem, message) {
        formElem.classList.remove(LOADING_CLASS)

        let formErrorElems = Array.from(formElem.querySelectorAll(`.${FORM_ERROR_CLASS}`))

        if (formErrorElems.length) {
            formErrorElems.forEach((formErrorElem) => {
                formErrorElem.remove()
            })
        }

        const errorElem = document.createElement('p')

        errorElem.innerHTML = message
        errorElem.classList.add('form-error', FORM_ERROR_CLASS)

        formElem.append(errorElem)
        const tokenElement = document.querySelector('.js-token')
        if(tokenElement) {
            grecaptcha.reset()
        }
    }

    async function fetchFaqForm(questionForm) {
        questionForm.classList.add(LOADING_CLASS)
        const tokenElement = document.querySelector('.js-token')
        if(tokenElement) {
            grecaptcha.ready(() => {
                grecaptcha.execute(tokenElement.dataset.token, {action: 'submit'}).then( async (token) => {
                    try {
                        const body = new FormData();
                        const fields = questionForm.querySelectorAll('input');
                        for (const field of fields) {
                            body.append(field.name, field.value)
                        }
                        body.append('recaptcha-token', token)

                        const response = await fetch(questionForm.action, {
                            method: questionForm.method,
                            body
                        })

                        const data = await response.json()
                        if (data.isSuccess) {
                            document.querySelector('.js-subtitle-partner').classList.add('hide')
                            showFormSuccess(questionForm, data.message)
                        } else {
                            showFormError(questionForm, data.message)
                        }

                    } catch (error) {
                        showFormError(questionForm, ERROR_MESSAGE)
                    }
                });
            });
        } else {
            try {
                const response = await fetch(questionForm.action, {
                    method: questionForm.method,
                    body: new FormData(questionForm)
                })
                const data = await response.json()
                if (data.isSuccess) {
                    document.querySelector('.js-subtitle-partner').classList.add('hide')
                    showFormSuccess(questionForm, data.message)
                } else {
                    showFormError(questionForm, data.message)
                }
            } catch (error) {
                showFormError(questionForm, ERROR_MESSAGE)
            }
        }
    }

    function onOpenPopup(popupElem) {
        const questionForm = popupElem.querySelector('.js-question-form')
        const inputTel = questionForm.querySelector('.js-input-tel');

        if (inputTel) {
            inputTel.oninput = function(){
                if (this.value.length < 2) {
                    this.value = "+7";
                }

                if (this.value.length > 12) {
                    this.value = this.value.substring(0, 12);
                }

                this.value = this.value.replace(/[^\d+]/g,'');
            }
        }


        if (questionForm) {
            const agreementBtn = questionForm.querySelector('.js-open-agreement')

            if (agreementBtn) {
                toggleAgreement(agreementBtn)
            }

            questionForm.addEventListener('submit', function (evt) {
                evt.preventDefault()

                fetchFaqForm(questionForm)
            })
        }
    }

    const openPopupBtns = Array.from(document.querySelectorAll('.js-open-popup-partner'))

    if (openPopupBtns.length) {
        openPopupBtns.forEach((btnElem) => {
            openPopupByBtn(btnElem, onOpenPopup)
        })
    }

    let currentLocationHash = window.location.hash;
    if (currentLocationHash === '#questionnaire') {
        openPopup('popup-partner', onOpenPopup)
    }

})()
